import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const seoQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            author
            siteDesc: description
            image
            siteUrl
            siteTitle: title
          }
        }
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const site = useStaticQuery(seoQuery)
  const { siteDesc, siteTitle, siteUrl, image, author } = site.allSite.edges[0].node.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "no" }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default SEO
// interface HelmetProps {
//   articlepathName?: string
//   authorName?: string
//   authorsBio?: string
//   authorsSlug?: string
//   canonicalUrl?: string
//   dateforSEO?: string
//   description?: string
//   image?: string
//   isBlogPost: false
//   pathname: string
//   published?: string
//   timeToRead?: string
//   title: string
//   isSecret: false
// }

// const themeUIDarkModeWorkaroundScript = [
//   {
//     type: "text/javascript",
//     innerHTML: `
//     (function() {
//       try {
//         var mode = localStorage.getItem('theme-ui-color-mode');
//         if (!mode) {
//           localStorage.setItem('theme-ui-color-mode', 'light');
//         }
//       } catch (e) {}
//     })();
//   `,
//   },
// ]

// const SEO: React.FC<HelmetProps> = ({
//   articlepathName,
//   authorName,
//   authorsBio,
//   authorsSlug,
//   canonicalUrl,
//   children,
//   dateforSEO,
//   description,
//   image,
//   isBlogPost,
//   pathname,
//   published,
//   timeToRead,
//   title,
//   isSecret,
// }) => {
//   const results = useStaticQuery(seoQuery)
//   const site = results.allSite.edges[0].node.siteMetadata
//   const twitter = site.social.find((option) => option.name === "twitter") || {}
//   const github = site.social.find((option) => option.name === "github") || {}
//   const linkedin = site.social.find((option) => option.name === "linkedin") || {}
//   const medium = site.social.find((option) => option.name === "medium") || {}

//   const pageUrl = site.siteUrl + pathname

//   const fullURL = (path: string) => (path ? `${path}` : site.siteUrl)

//   // If no image is provided lets looks for a default novela static image
//   image = image ? image : `${site.siteUrl}/preview.jpg`

//   let siteSchema = `{
//     "@context": "https://schema.org",
//     "@graph": [
//       {
//         "@type": "Organization",
//         "@id": "${site.siteUrl}/#organization",
//         "name": "${site.title}",
//         "url": "${site.siteUrl}",
//         "sameAs": [
//           "${twitter.url}",
//           "${github.url}",
//           "${linkedin.url}",
//           "${medium.url}"
//         ],
//         "logo": {
//           "@type": "ImageObject",
//           "@id": "${site.siteUrl}/#logo",
//           "inLanguage": "en-US",
//           "url": "${site.siteUrl}/icons/icon-512x512.png",
//           "width": 512,
//           "height": 512,
//           "caption": "${site.title}"
//         },
//         "image": {
//           "@id": "${site.siteUrl}/#logo"
//         }
//       },
//       {
//         "@type": "WebSite",
//         "@id": "${site.siteUrl}/#website",
//         "url": "${site.siteUrl}",
//         "name": "${site.name}",
//         "description": "${site.description}",
//         "publisher": {
//           "@id": "${site.siteUrl}/#organization"
//         },
//         "inLanguage": "en-US"
//       },
//       {
//         "@type": [
//           "WebPage"
//         ],
//         "@id": "${pageUrl}/#webpage",
//         "url": "${pageUrl}",
//         "name": "${title || site.name}",
//         "isPartOf": {
//           "@id": "${site.siteUrl}/#website"
//         },
//         "about": {
//           "@id": "${site.siteUrl}/#organization"
//         },
//         "description": "${description || site.description}",
//         "inLanguage": "en-US"
//       },
//       {
//         "@type": "BreadcrumbList",
//         "description": "Breadcrumbs list",
//         "itemListElement": [
//           {
//             "@type": "ListItem",
//             "item": "${site.siteUrl}",
//             "name": "Homepage",
//             "position": "1"
//           }
//         ],
//         "name": "Breadcrumbs"
//       }
//     ]
//   }
// `.replace(/"[^"]+"|(\s)/gm, function (matched, group1) {
//     if (!group1) {
//       return matched
//     } else {
//       return ""
//     }
//   })

//   //   let blogSchema = `{
//   //     "@context": "https://schema.org",
//   //     "@graph": [
//   //       {
//   //         "@type": "Organization",
//   //         "@id": "${site.siteUrl}/#organization",
//   //         "name": "${site.title}",
//   //         "url": "${site.siteUrl}",
//   //         "sameAs": [
//   //           "${twitter.url}",
//   //           "${github.url}",
//   //           "${linkedin.url}",
//   //           "${medium.url}"
//   //         ],
//   //         "logo": {
//   //           "@type": "ImageObject",
//   //           "@id": "${site.siteUrl}/#logo",
//   //           "inLanguage": "en-US",
//   //           "url": "${site.siteUrl}/icons/icon-512x512.png",
//   //           "width": 512,
//   //           "height": 512,
//   //           "caption": "${site.title}"
//   //         },
//   //         "image": {
//   //           "@id": "${site.siteUrl}/#logo"
//   //         }
//   //       },
//   //       {
//   //         "@type": "WebSite",
//   //         "@id": "${site.siteUrl}/#website",
//   //         "url": "${site.siteUrl}",
//   //         "name": "${site.name}",
//   //         "description": "${site.description.replace(/"/g, '\\"')}",
//   //         "publisher": {
//   //           "@id": "${site.siteUrl}/#organization"
//   //         },
//   //         "inLanguage": "en-US"
//   //       },
//   //       {
//   //         "@type": "ImageObject",
//   //         "@id": "${articlepathName}/#primaryimage",
//   //         "inLanguage": "en-US",
//   //         "url": "${image}",
//   //         "width": 1200,
//   //         "height": 628
//   //       },
//   //       {
//   //         "@type": [
//   //           "WebPage"
//   //         ],
//   //         "@id": "${articlepathName}/#webpage",
//   //         "url": "${articlepathName}",
//   //         "name": "${title}",
//   //         "isPartOf": {
//   //           "@id": "${site.siteUrl}/#website"
//   //         },
//   //         "primaryImageOfPage": {
//   //           "@id": "${articlepathName}/#primaryimage"
//   //         },
//   //         "datePublished": "${dateforSEO}",
//   //         "dateModified": "${dateforSEO}",
//   //         "description": "${description}",
//   //         "breadcrumb": {
//   //           "@id": "${articlepathName}/#breadcrumb"
//   //         },
//   //         "inLanguage": "en-US"
//   //       },
//   //       {
//   //         "@type": "BreadcrumbList",
//   //         "@id": "${articlepathName}/#breadcrumb",
//   //         "itemListElement": [
//   //           {
//   //             "@type": "ListItem",
//   //             "position": 1,
//   //             "item": {
//   //               "@type": "WebPage",
//   //               "@id": "${site.siteUrl}",
//   //               "url": "${site.siteUrl}",
//   //               "name": "Home"
//   //             }
//   //           },
//   //           {
//   //             "@type": "ListItem",
//   //             "position": 2,
//   //             "item": {
//   //               "@type": "WebPage",
//   //               "@id": "${articlepathName}",
//   //               "url": "${articlepathName}",
//   //               "name": "${title}"
//   //             }
//   //           }
//   //         ]
//   //       },
//   //       {
//   //         "@type": "Article",
//   //         "@id": "${articlepathName}/#article",
//   //         "isPartOf": {
//   //           "@id": "${articlepathName}/#webpage"
//   //         },
//   //         "author": {
//   //           "@id": "${site.siteUrl}/#/schema${authorsSlug}"
//   //         },
//   //         "headline": "${title}",
//   //         "datePublished": "${dateforSEO}",
//   //         "dateModified": "${dateforSEO}",
//   //         "mainEntityOfPage": {
//   //           "@id": "${articlepathName}/#webpage"
//   //         },
//   //         "publisher": {
//   //           "@id": "${site.siteUrl}/#organization"
//   //         },
//   //         "image": {
//   //           "@id": "${articlepathName}/#primaryimage"
//   //         },
//   //         "inLanguage": "en-US"
//   //       },
//   //       {
//   //         "@type": [
//   //           "Person"
//   //         ],
//   //         "@id": "${site.siteUrl}/#/schema${authorsSlug}",
//   //         "name": "${authorName}",
//   //         "image": {
//   //           "@type": "ImageObject",
//   //         "@id": "${site.siteUrl}/#personlogo",
//   //           "inLanguage": "en-US",
//   //           "caption": "${authorName}"
//   //         },
//   //         "description": "${authorsBio}",
//   //         "sameAs": [
//   //           "${twitter.url}",
//   //           "${github.url}",
//   //           "${linkedin.url}",
//   //           "${medium.url}"
//   //         ]
//   //       }
//   //     ]
//   //   }
//   // `.replace(/"[^"]+"|(\s)/gm, function (matched, group1) {
//   //     if (!group1) {
//   //       return matched
//   //     } else {
//   //       return ''
//   //     }
//   //   })

//   // const schema = isBlogPost ? blogSchema : siteSchema

//   const metaTags = [
//     { charset: "utf-8" },
//     {
//       "http-equiv": "X-UA-Compatible",
//       content: "IE=edge",
//     },
//     {
//       name: "viewport",
//       content: "width=device-width, initial-scale=1",
//     },
//     {
//       name: "theme-color",
//       content: "#fff",
//     },
//     { itemprop: "name", content: title || site.title },
//     { itemprop: "description", content: description || site.description },
//     { itemprop: "image", content: image },
//     { name: "description", content: description || site.description },

//     {
//       /* Twitter Card tags */
//     },
//     { name: "twitter:card", content: "summary_large_image" },
//     { name: "twitter:site", content: site.name },
//     { name: "twitter:title", content: title || site.title },
//     { name: "twitter:description", content: description || site.description },
//     { name: "twitter:creator", content: twitter.url },
//     {
//       name: "twitter:image",
//       content: image,
//     },
//     {
//       /* OpenGraph tags */
//     },
//     { property: "og:type", content: "website" },
//     { property: "og:title", content: title || site.title },
//     { property: "og:url", content: articlepathName || pageUrl },
//     { property: "og:image", content: image },
//     { property: "og:description", content: description || site.description },
//     { property: "og:site_name", content: site.name },
//   ]

//   if (published) {
//     metaTags.push({ name: "article:published_time", content: published })
//   }

//   if (timeToRead) {
//     // metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
//     // metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` })
//   }

//   if (isSecret) {
//     metaTags.push({ name: "robots", content: "noindex" })
//   }

//   return (
//     <Helmet
//       title={title || site.title}
//       htmlAttributes={{ lang: "en" }}
//       script={themeUIDarkModeWorkaroundScript}
//       meta={metaTags}
//     >
//       {/* Schema.org tags */}
//       {/* <script type="application/ld+json">{schema}</script> */}
//       {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
//       {children}
//     </Helmet>
//   )
// }

// export default SEO
